import React, { useEffect } from "react";
import "./App.css";
import Navbar from "./components/Navbar";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import usePageTracking from "./usePageTracking";

import Home from "./components/pages/Home";
import Sobre from "./components/pages/Sobre";
import Espaços from "./components/pages/Espaços";
import Parcerias from "./components/pages/Parcerias";
import Contactos from "./components/pages/Contactos";
import Loja from "./components/pages/Loja";
import Footer from "./components/Footer";
import Espaçosdetalhes from "./components/pages/Espaçosdetalhes";
import Parceriasdetalhes from "./components/pages/Parceriasdetalhes";
import Lojadetalhes from "./components/pages/Lojadetalhes";
import ItemDetails from "./components/pages/ItemDetails";
import notFound from "./components/pages/notFound";
import commingSoon from "./components/pages/comingSoon";

function App() {
	// usePageTracking();

	return (
		<>
			<Router>
				<Navbar />
				<Switch>
					<Route path="/" exact component={Home} />
					<Route path="/sobre" exact component={Sobre} />
					<Route path="/espacos" exact component={Espaços} />
					<Route
						path="/espacos/espacosdetalhes"
						exact
						component={Espaçosdetalhes}
					/>
					<Route path="/contactos" exact component={Contactos} />

					<Route path="/parcerias" exact component={commingSoon} />
					<Route path="/loja" exact component={commingSoon} />

					{/* <Route path="/parcerias" exact component={Parcerias} />
          <Route path="/parcerias/parceriasdetalhes" exact component={Parceriasdetalhes} />
          <Route path="/loja" exact component={Loja} />
          <Route path="/loja/lojadetalhes" exact component={Lojadetalhes} />
          <Route path="/loja/lojadetalhes/item" exact component={ItemDetails} /> */}
					<Route path="/reservar" exact component={Espaços} />
					<Route exact component={notFound} />
				</Switch>
				<Footer />
			</Router>
		</>
	);
}

export default App;
