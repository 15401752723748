import React, { useEffect, useState } from "react";
import FooterImage from "../assets/images/logos/footer.png";
import FooterCofinance from "../assets/images/logos/svg/COFINANCIAMENTO_Footer.svg";
import { useTranslation } from "react-i18next";
import TermsAndConditions from "../components/modalPopup/TermsAndConditions";
import TncFooterPopup from "./modalPopup/TncFooterPopup";

import "./Footer.css";

function Footer() {
	const [t, i18n] = useTranslation("translations");
	const [showPolicy, setShowPolicy] = useState(false);
	const [showTnc, setShowTnc] = useState(false);
	const [tacFiles, setTacFiles] = useState([]);

	const getData = () => {
		const spaceData = t("espaçosDetalhes.espaços", { returnObjects: true });
		const finalData = spaceData[Object.keys(spaceData)[0]].coworks;

		const mappedFiles = finalData
			.filter((el) => el.online)
			.map((cowork) => ({
				title: cowork.title,
				location: cowork.location,
				file: cowork.tncPDF,
			}));

		setTacFiles(mappedFiles);
	};

	// biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
	useEffect(() => {
		if (tacFiles.length < 1) getData();
	}, [tacFiles]);

	// biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
	useEffect(() => {
		getData();
	}, [i18n.language]);

	return (
		<section className="footer-container">
			<div className="left-side">
				<img
					className="left-footer-bar"
					src={FooterImage}
					alt="COWORK @ ALDEIAS DE MONTANHA"
					width="300px"
					height="auto"
				/>
				<img
					className="right-footer-bar"
					src={FooterCofinance}
					alt="cofinanciamento_bar"
					width="auto"
					height="55px"
				/>
			</div>
			<div style={{ display: "flex", gap: 20 }}>
				<p className="termsAndConditions" onClick={() => setShowTnc(true)}>
					{t("espaçosDetalhes.TERMOS")}
				</p>
				<p className="termsAndConditions" onClick={() => setShowPolicy(true)}>
					{t("privacidade")}
				</p>
			</div>
			<TermsAndConditions
				trigger={showPolicy}
				language={i18n.language}
				close={() => setShowPolicy(false)}
			/>
			<TncFooterPopup
				trigger={showTnc}
				data={tacFiles}
				close={() => setShowTnc(false)}
			/>
		</section>
	);
}

export default Footer;
