import React, { useState } from "react";
import ReactTooltip from "react-tooltip";
import { useTranslation } from "react-i18next";
import { Document, Page } from "react-pdf/dist/esm/entry.webpack";
import { Tab as MuiTab, Tabs } from "@mui/material";

import "./tncmodalpopup.css";
import "../../App.css";

function TncFooterPopup(props) {
	const [tab, setTab] = useState(0);

	const [t] = useTranslation("translations");

	return props.trigger ? (
		<div className="tnc-background-div">
			<div className="tnc-popup">
				<div className="tnc-navigation-div">
					<p>{t("espaçosDetalhes.TERMOS")}</p>
					<div className="tnc-btn-div">
						{/* biome-ignore lint/a11y/useKeyWithClickEvents: <explanation> */}
						<div
							data-tip
							data-for="close-btn"
							className="fas fa-window-close"
							onClick={props.close}
						/>
					</div>
				</div>
				<Tabs value={tab} onChange={(_, value) => setTab(value)} centered>
					{props.data.map((el) => (
						<Tab key={el.title} label={el.title} />
					))}
				</Tabs>

				<Content data={props.data[tab]} t={t} />
			</div>
			<ReactTooltip id="close-btn" effect="solid">
				<span>{t("tooltips.fechar")}</span>
			</ReactTooltip>
		</div>
	) : (
		""
	);
}

export default TncFooterPopup;

const Content = ({ data, t }) => {
	const [numPages, setNumPages] = useState(null);

	function onDocumentLoadSuccess({ numPages }) {
		setNumPages(numPages);
	}

	return (
		<div className="content-div">
			{data?.file ? (
				<Document
					file={`${process.env.REACT_APP_SERVER_URL}/resources/files/coworks/${data.file}`}
					onLoadSuccess={onDocumentLoadSuccess}
					loading="Aguarde por favor..."
				>
					{Array.from(new Array(numPages), (el, index) => (
						<Page
							key={`page_${index + 1}`}
							pageNumber={index + 1}
							width={1000}
							renderTextLayer={false}
						/>
					))}
				</Document>
			) : (
				<p>{`${t("tac.noPDF")} ${data?.title}`}</p>
			)}
		</div>
	);
};

function Tab(props) {
	return (
		<MuiTab
			{...props}
			sx={{
				color: "var(--text-secondary)",
				fontSize: 16,
				transition: "all 0.2s ease-in-out",
				"&:hover": {
					color: "var(--secondary)",
				},
				"&.Mui-selected": {
					color: "var(--secondary)",
				},
			}}
		/>
	);
}
