import { CoworksPT } from "../components/data/Spaces-Data";
import { partnerPT } from "../components/data/parcerias-Data";
import {
	MobiliarioPT,
	IluminariaPT,
	TapecariaPT,
	DecoracaoPT,
} from "../components/data/Store-Data";

const portugues = {
	navbar: {
		sobre: "SOBRE",
		espaços: "ESPAÇOS COWORK",
		parcerias: "PARCERIAS",
		contactos: "CONTACTOS",
		loja: "LOJA",
		reservar: "RESERVAR",
	},

	sobre: {
		title1: "COWORK",
		subtitle1: "COOPERATIVA",
		headline: `Respire o ar puro.
        Inspire-se na natureza.
        E aspire o sucesso… `,
		text1: `Dizem que o sucesso no trabalho é 99% transpiração e 1% inspiração. Não aqui. Na Cooperativa Cowork, o conceito de coworking rural foi elevado a um nível superior, literalmente: o alto da Serra da Estrela. Lugares onde, ao longo de séculos, as pessoas e a natureza têm convivido de forma harmoniosa. Um ambiente que permite ter espaços de trabalho partilhados inusitados, com design inovador e todas as estruturas de comunicação, com um sistema de internet de elevada performance.

        RESPIRE – O nome é cowork, mas poderíamos chamar co-happiness. Porque dá gosto trabalhar num ambiente assim. O ar puro. O verde. Ou o silêncio entrecortado pelo canto dos pássaros, pelo murmúrio do vento ou pela lida do aldeões no seu dia a dia. Nos espaços da Cooperativa Cowork, sempre que precisar fazer uma pausa do trabalho só tem de dar um salto lá fora, respirar com calma e recarregar as baterias.

        INSPIRE-SE – Os espaços de trabalho partilhados que integram a Cooperativa Cowork são inspiradores. O design resulta da busca do equilíbrio entre o ambiente rural e a funcionalidade dos equipamentos. As peças foram criadas por designers e produzidas por artesãos ou indústria local. Cada um dos espaços resulta de um olhar atento para a ecologia humana, tendo o foco na economia circular e design quilómetro zero. Muitos dos materiais usados nos espaços são naturais, sustentáveis e da própria região, e muitas peças poderão ser adquiridas por encomenda na Loja deste website.

        ASPIRE O SUCESSO – Um retiro artístico. Uma atividade de team building. Um lugar para escrever uma tese. Na Cooperativa Cowork os espaços oferecem inúmeras opções de uso. Mais do que isso, têm o ambiente perfeito para quem deseja trabalhar em condições inspiradoras. A Cooperativa Cowork mostra que a melhor maneira de sair da zona de conforto é entrar para uma zona de conforto ainda melhor.`,

		title2: "ALDEIAS DE MONTANHA",
		subtitle2: "EMPREENDORISMO",
		text2:
			"O espaço de Coworking nas Aldeias de Montanha, procura criar uma oferta dinamizadora que active a vivência da Aldeia, e que ofereça aos seus utilizadores a oportunidade de trabalharem num espaço inovador, inspirador e inusitado.\n\nNos dias de hoje, pela facilidade que temos na conexão ao mundo através da internet, o espaço físico onde trabalhamos deixou de ser um constrangimento, e muitas pessoas procuram terem mais qualidade de vida afastando-se dos grandes centros urbanos, procurando um maior contacto com a natureza.\n\nEste projecto procura também integrar a comunidade local, usando os recursos regionais, como a mão de obra do artesão, ou a peça de artesanato produzido na região, ou re-utilizando objetos em fim de vida, reciclando-os ou restaurando-os, fazendo deste um projecto com uma forte componente ecológica, importando conceitos como o eco design e economia circular.\n\nEstes são espaços têm uma identidade própria, são espaços criativos para pessoas empreendedoras e criativas que procuram uma vida melhor, uma vida mais tranquila, sem perderem a produtividade e a relação com outros pares, com quem podem trocar ideias e experiências. Trata-se de um espaço de cooperação e interacção.",
	},

	espaços: {
		title: "CONHEÇA OS ESPAÇOS COWORK",
		text: `Os espaços de trabalho da Cooperativa Cowork foram pensados para dar
        respostas aos mais diversos tipos de exigências. São espaços sem fins de
        lucrativos, dinamizados pela Rede de Aldeias de Montanha em parceria com
        as Juntas de Freguesias locais e Municípios.
        Desde os mais pequenos ao mais espaçosos, a proposta passa pelo
        equilíbrio entre trabalhar em condições invejáveis e desfrutar de um
        ambiente rural agradável e funcional, na Serra da Estrela. Pense alto, porque
        um destes espaços é a resposta perfeita às suas exigências. `,
	},

	espaçosDetalhes: {
		form: {
			title: "RESERVE",
			quantidade: "QUANTIDADE",
			pessoa: "Pessoa",
			pessoas: "Pessoas",
			nome: "NOME",
			localizaçao: "MORADA",
			nif: "NIF/PASSAPORTE",
			telemovel: "TELEMOVEL",
			"sub-name": "(PRIMEIRO E ULTIMO)",
			botão: "SUBMETER",
		},

		termos: `Li e aceito os `,
		termos_link: "termos e condições",
		TERMOS: "Termos e Condições",

		calendar: {
			texto: "SELECIONE OS DIAS NO CALENDÁRIO",
			de: "DE",
			ate: "ATÉ",
		},

		popup: {
			popupMessage:
				"O seu pedido foi enviado com sucesso\n\nEntraremos em contacto o mais depressa possivel\n\nObrigado",
			popupMessageError:
				"Pedimos desculpa\n\nNão conseguimos processar o seu pedido neste momento\n\nPor favor tente mais tarde",
		},

		espaços: { CoworksPT },
	},

	parcerias: {
		text: `Quando todos caminham na mesma direção, o resultado só pode ser o sucesso.
        A Cowork Cooperativa é um projeto que surgiu da ideia de estimular o
        empreendedorismo e desenvolver a economia das comunidades que integram a
        rede das Aldeias de Montanha. Mas os caminhos são mais fáceis de trilhar
        quando é possível contar com parceiros que partilham os mesmos interesses. O
        sucesso é de todos. `,
		institucionais: "PARCERIAS INSTITUCIONAIS ",
		individuais: "ARTESÃOS E INDUSTRIA LOCAL ",
		empresas: "SPONSORS ",
		individuais_cards: { partnerPT },
	},

	contactos: {
		title: "ENTRE EM CONTACTO CONNOSCO",
		text: "Precisa de ajuda ou tem alguma duvida? Não hesite em contactar-nos. Entraremos em contacto o mais rapido possivel",
		nome: "NOME",
		"sub-name": "(PRIMEIRO E ULTIMO)",
		phone: "TELEMOVEL",
		mensagem: "MENSAGEM",
		botão: "SUBMETER",
		popupMessage:
			"O seu email foi enviado com sucesso.\n\nEntraremos em contacto o mais depressa possivel\n\n",
		popupMessageError:
			"Pedimos desculpa\n\nNão conseguimos processar o seu pedido neste momento\n\nPor favor tente mais tarde",
	},

	loja: {
		mobiliario: "MOBILIÁRIO",
		iluminaria: "ILUMINÁRIA",
		tapeçaria: "TAPEÇARIA",
		decoração: "DECORAÇÂO",
		mobiliario_data: { MobiliarioPT },
		iluminaria_data: { IluminariaPT },
		tapecaria_data: { TapecariaPT },
		decoraçao_data: { DecoracaoPT },
	},

	calendar: {
		meses: [
			"JANEIRO",
			"FEVEREIRO",
			"MARÇO",
			"ABRIL",
			"MAIO",
			"JUNHO",
			"JULHO",
			"AGOSTO",
			"SETEMBRO",
			"OUTUBRO",
			"NOVEMBRO",
			"DEZEMBRO",
		],
		semana_long: [
			"Domingo",
			"Segunda-feira",
			"Terça-feira",
			"Quarta-feira",
			"Quinta-feira",
			"Sexta-feira",
			"Sabado",
		],
		semana_short: ["Dom", "Seg", "Ter", "Qua", "Qui", "Sex", "Sab"],
	},

	lojaDetalhes: {
		dimensoes: "DIMENSÕES",
		materiais: "MATERIAIS",
		origem: "ORIGEM / ARTESÃO",
		entrega: "TEMPO DE ENTREGA",
		preço: "PREÇO",
		condiçoes: "CONDIÇÕES",
		botao: "COMPRAR",
	},

	validation: {
		nomeInvalido: "Nome inválido",
		emailInvalido: "Email inválido",
		mensagem: "Escreva uma mensagem, por favor",
	},

	common: {
		sucesso: "Sucesso",
		erro: "Erro",
		submit: "Submeter",
		cancel: "Cancelar",
	},

	tooltips: {
		reservar: "Reservar",
		ver360: "Ver 360º",
		info: "Mais info",
		fechar: "Fechar",
		"nova-janela": "Abrir Nova Janela",
	},

	commingSoon: {
		title: "Brevemente",
		subtitle: "Esta página encontra-se em desenvolvimento",
		button: "Voltar",
	},

	privacidade: "Política de Privacidade",

	feedback: {
		title: "Dê-nos a sua opinião",
		subtitle:
			"Agradecemos imenso que nos dê a sua opinião acerca do cowork que visitou",
		field: {
			name: "Nome",
			question1: "Como foi a sua experiência no geral?",
			question2:
				"Pensando na sua experiência de trabalho no espaço de Cooperativa Cowork @Aldeias de Montanha tem algum comentário sobre como poderíamos melhorar a sua experiência numa próxima visita?",
			question3:
				"Qual é a probabilidade de voltar a trabalhar neste cowork novamente?",
			question4:
				"Qual é a probabilidade de recomendar o espaço Cooperativa Cowork @Aldeias de Montanha a um amigo ou familiar?",
			question5:
				"Como classifica a qualidade da internet e dos demais dispositivos disponíveis?",
			question6:
				"Considera que teve tudo o que precisava no espaço de cowork? Se não, o que acrescentaria neste espaço (ex. eletrodomésticos, impressoras...)?",
		},
		button: "Enviar Feedback",
		ratingLevels: {
			1: "Horrivel",
			2: "Muito Má",
			3: "Má",
			4: "Não muito boa",
			5: "Mais ou menos",
			6: "Boa",
			7: "Muito Boa",
			8: "Adorei",
			9: "Excelente",
			10: "Magnífico",
		},
		ratingLevels2: {
			1: "Nenhuma",
			2: "Pouco Provável",
			3: "Provável",
			4: "Muito Provavél",
			5: "Certamente",
		},
		ratingLevels3: {
			1: "Nenhuma",
			2: "Pouco Provável",
			3: "Provável",
			4: "Muito Provavél",
			5: "Certamente",
		},
		ratingLevels4: {
			1: "Muito Má",
			2: "Má",
			3: "Razoavel",
			4: "Boa",
			5: "Muito Boa",
		},
		confirmationModal: {
			success:
				"Obrigado pelo seu Feedback, esperemos poder recebê-lo novamente em breve.",
			fail: "Não foi possivel enviar o seu feedback, por favor tente mais tarde. Se o problema persistir, por favor, contacte-nos.",
		},
	},

	feedbackGiven: {
		title: "Obrigado pelo seu feedback",
		subtitle:
			"Agradecemos o tempo de dispensou a dar-nos o seu feedback, esperemos voltar a vê-lo em breve",
	},

	hourpicker: {
		title: "Escolha a hora de entrada e saida",
		note: "Todas as datas são para ser consideradas GMT +00:00",
	},

	reservation: {
		dateContainer: {
			title:
				"Comece por escolher o número de pessoas, em seguida selecione os dias no calendário e depois as horas",
			notAvailableMessage:
				"A sua seleção de dias contem dias que não estão disponíveis, por favor escolha novamente",
			people: {
				single: "Pessoa",
				plural: "Pessoas",
				title: "Selecione o número de pessoas",
				person: "Pessoa selecionada",
				people: "Pessoas selecionadas",
			},
			calendar: {
				title:
					"Clique uma vez para selecionar o dia de entrada e outra vez para selecionar o dia de saida",
				disclaimer:
					"Dias a cinzento não estão disponíveis ou já estão reservados",
			},
			hours: {
				title: "Selecione a hora de entrada e a hora de saida",
				disclaimer:
					"Horas a cinzento não estão disponíveis ou já estão reservadas",
				subtitle: "Selecione um dia no calendário",
				checkInSubtitle: "Selecione uma hora de entrada",
				checkOutSubtitle: "Selecione uma hora de saída",
				tooltip: " pessoas já reservaram esta hora",
				tooltipEmpty: "Não existem reservas a esta hora",
				seatsOccupied: "Lugares ocupados",
				unavailable: {
					first: "Não existem lugares disponíveis nos dias selecionados",
					second: "não tem lugares disponíveis",
				},
			},
			addMore: "Adicionar mais datas",
		},
		topInfo: {
			checkIn: "Data de entrada",
			checkOut: "Data de saída",
			people: "Número de pessoas",
		},
		form: {
			title: "Preencha o seguinte formulário",
			subtitle: "Preencha o formulário coma  sua informação",
			name: {
				first: "Nome",
				second: "(Primeiro e ultimo)",
			},
			address: "Localidade",
			phone: "Número de telemóvel",
			nif: "NIF/Passaporte",
			message: "Observações/Pedidos",
			messagePlaceholder:
				"Escreva aqui se tiver algum pedido especial ou se quiser fazer alguma observação sobre a sua reserva",
			errors: {
				required: "Este campo é obrigatório",
				name: "não é um nome válido",
				address:
					"não é uma localização válida, escreva apenas a sua localização/cidade, ex: Lisboa",
				nif: "não é um NIF/Passaporte valido",
				phone: "não é um número de telefone válido",
				email: "não é um email válido",
			},
			required: "Campo Obrigatório",
		},
		review: {
			title: "Verifique os dados",
			subtitle:
				"Verifique toda a informação sobre a reserva e também do formulário à esquerda",
			checkIn: "Data de entrada",
			checkOut: "Data de saída",
			people: "Número de pessoas",
			nPeople: "Pessoas",
			location: "Localização",
			note: {
				title: "Nota importante",
				text: "Esta reserva só é valida para o espaço cowork, não incluindo pernoita. Se necessita de alojamento contacte-nos através do formulário neste website na pagina contactos ",
			},
			shareNote: {
				title: "Partilha de Dados",
				text: "Autoriza a partilha de dados pessoais (email,nome) para futuras entrevistas e/ou trabalhos académicos?",
				yes: "Sim",
				no: "Não",
			},
		},
		actions: {
			tnc: {
				first: "Li e aceito os ",
				second: "termos e condições",
			},
			button: "Submeter reserva",
		},
		confirmation: {
			titles: {
				title1: "A registar a sua reserva...",
				title2: "Reserva criada com sucesso",
				title3: "Ocorreu um erro ao criar a sua reserva",
			},
			body: {
				body1: "Por favor aguarde enquanto registamos a sua reserva",
				body2:
					"A sua reserva foi registada com sucesso, deverá receber em breve um email a confirmar a reserva e com todos os detalhes.\n\nEsperamos por si em",
				body3:
					"Ocorreu um erro ao registar a sua reserva, por favor tente mais tarde\n\n",
			},
		},
		contact: {
			link1:
				"Para grupos superiores à capacidade maxima instalada ou um pedido especial?",
			link2: " e nós ajudamos!",
			span: "Clique aqui",
			title: "Reserva especial",
			subtitle:
				"Se gostaria de fazer uma reserva mas tem um grupo superior à capacidade máxima instalada ou precisa de mais informação, preencha este formulário e indique-nos todos pormenores e requisitos para que possamos ajuda-lo da melhor maneira",
		},
	},
	tac: {
		noPDF: "Não foi possivel apresentar o os termos e condições de ",
	},
};

export default portugues;
